import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Card, CardBody, Col, Row, Text } from "@jarvis-catalyst/ui-components";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutationDocument } from "../../hooks/useMutationDocument";
import { blocklistOptions } from "../../config/data/userOptions";
import { useUser } from "../../hooks/useUser";
import { validateIfHasPermissionToAccessView } from "../../utils/filterPermission";
import { COUNTRIES_DOCUMENT_TYPES, DOCUMENT_REASON } from "../../config/constants";
import { DocumentTypeForCountryType } from "../../types/Document";
import { useKeycloakServiceProviders } from "../../hooks/useKeycloakServiceProvider";

type Inputs = {
  documentNumber: string;
  serviceProvider: string;
  operation: string;
  reason: string;
  documentType: string;
};

const CreateDocumentLockUnlock = (): ReactElement => {
  const navigate = useNavigate();
  const { keycloakServiceProviderUseQuery } = useKeycloakServiceProviders();
  const { mutate, isSuccessAction } = useMutationDocument();
  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentTypeForCountryType | undefined>(
    COUNTRIES_DOCUMENT_TYPES[0]
  );
  const [blocklist] = useState(blocklistOptions);
  const { permission } = useUser();

  const hasValidPermission = blocklist.some(({ validPermissions }) => {
    return validateIfHasPermissionToAccessView({
      validPermissionsView: validPermissions,
      userPermission: permission,
      action: "update"
    });
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async ({
    documentNumber,
    operation,
    reason,
    serviceProvider,
    documentType
  }) => {
    await mutate({
      documentNumber,
      operation,
      reason,
      serviceProvider,
      documentType
    });
  };

  const handleChangeServiceProvider = (
    _e: React.ChangeEvent<HTMLInputElement>,
    _countriesDoocumentTypes: DocumentTypeForCountryType[]
  ): void => {
    const { target } = _e;
    const country: string = target.value.split("-").slice(-1)[0];
    const foundCountryDocumentType: DocumentTypeForCountryType | undefined = _countriesDoocumentTypes.find(
      (_item) => _item.country === country
    );
    setSelectedDocumentType(foundCountryDocumentType);
  };

  useEffect(() => {
    if (isSuccessAction) {
      reset();
    }
  }, [isSuccessAction]);

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <Card className="shadow">
            <CardBody>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase text-center">Bloqueo/Desbloqueo de documento</h2>
                <hr />
                <form className="mb-1" onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-1">
                    <Text>Pais/Negocio:</Text>
                    <select
                      className={`form-control ${errors.serviceProvider ? "is-invalid" : ""}`}
                      {...register(`serviceProvider`, {
                        required: true,
                        value: "",
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeServiceProvider(e, COUNTRIES_DOCUMENT_TYPES)
                      })}
                    >
                      <option disabled value="">
                        Seleccione una opción
                      </option>
                      {keycloakServiceProviderUseQuery.data?.map((item, index) => (
                        <option key={index} value={item.serviceProvider}>
                          {item.serviceProvider}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <Text>Tipo de documento:</Text>
                    <select
                      className={`form-control ${errors.operation ? "is-invalid" : ""}`}
                      {...register(`documentType`, { required: true, value: "" })}
                    >
                      <option disabled value="">
                        Seleccione una opción
                      </option>
                      {selectedDocumentType?.documentTypes.map((_item, _index) => (
                        <option value={_item} key={_index}>
                          {_item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <Text>Número de documento:</Text>
                    <input
                      type="text"
                      className={`form-control ${errors.documentNumber ? "is-invalid" : ""}`}
                      {...register("documentNumber", { required: true })}
                    />
                  </div>
                  <div className="mb-3">
                    <Text>Operación:</Text>
                    <select
                      className={`form-control ${errors.operation ? "is-invalid" : ""}`}
                      {...register(`operation`, {
                        required: true,
                        value: ""
                      })}
                    >
                      <option disabled value="">
                        Seleccione una opción
                      </option>
                      <option value="lock">Bloquear</option>
                      <option value="unlock">Desbloquear</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Text>Razón:</Text>
                    <select
                      className={`form-control ${errors.reason ? "is-invalid" : ""}`}
                      {...register("reason", { required: true })}
                    >
                      {DOCUMENT_REASON.map((_item, _index) => (
                        <option value={_item.value} key={_index}>
                          {_item.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-grid">
                    <Button
                      color="primary"
                      className="mt-2 text-center"
                      disabled={!hasValidPermission}
                      type="submit"
                    >
                      Guardar
                    </Button>
                    <Button
                      disabled={false}
                      color="bordered"
                      type="button"
                      className="mt-2 text-center"
                      onClick={() => navigate("/user-management")}
                    >
                      Volver
                    </Button>
                  </div>
                </form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateDocumentLockUnlock;
