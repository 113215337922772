import { UseQueryResult, useQuery } from "react-query";
import { ServiceProvider, useQueryApiError } from "../types";
import { useUser } from "./useUser";
import { getAllKeycloakServiceProviderService } from "../services/getAllKeycloakServiceProviderService";

const keyCache = `keycloakServiceProviders`;

type KeycloakServiceProviderHookType = {
  keycloakServiceProviderUseQuery: UseQueryResult<ServiceProvider[], useQueryApiError>;
};

export const useKeycloakServiceProviders = (): KeycloakServiceProviderHookType => {
  const { user } = useUser();
  const keycloakServiceProviderUseQuery = useQuery<ServiceProvider[], useQueryApiError>(
    [keyCache],
    async () => {
      const response = await getAllKeycloakServiceProviderService({ token: user.token });

      return response.data;
    },
    { cacheTime: 5000 }
  );

  return {
    keycloakServiceProviderUseQuery
  };
};
