import mammoth from 'mammoth';

const convertFileToHtml = async (files: FileList | null): Promise<string | undefined> => {
  if (!files) {
    console.log("No files selected");
    return;
  }

  const [file] = files;
  
  try {
    const result = await mammoth.convertToHtml({
      arrayBuffer: await file.arrayBuffer(),
    });
    return result.value
  } catch (error) {
    console.error(error);
  }
};

export { convertFileToHtml };