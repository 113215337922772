import React, { FormEvent, ReactElement, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Input,
  Row,
  Select,
  Text
} from "@jarvis-catalyst/ui-components";
import { useNavigate } from "react-router-dom";
import { DeleteActionLocationState, DeleteLocationState } from "../../types";
import { FormAlertGroup } from "../../styled-components/FormGroup";
import { useValidateForm } from "../../hooks/useValidateForm";
import { useUtils } from "../../hooks/useUtils";
import { keycloakServiceProvidersOptionsList } from "../../utils/listOptions";
import { deleteUserOption, deleteAccountOption } from "../../config/data/deleteOptions";
import { validateIfHasPermissionToAccessView } from "../../utils/filterPermission";
import { useUser } from "../../hooks/useUser";
import { InfoModulesType } from "../../types/InfoModulesType";
import { useKeycloakServiceProviders } from "../../hooks/useKeycloakServiceProvider";

const initSelectedValue = {
  value: "",
  label: ""
};

type Props = {
  action: DeleteActionLocationState;
  title: string;
};

const DeleteKeycloakForm = ({ action, title }: Props): ReactElement => {
  const navigate = useNavigate();

  const { keycloakServiceProviderUseQuery } = useKeycloakServiceProviders();
  const { error, loadingValidate, setForm, validateForm, form } = useValidateForm();
  const { setServiceProvider, setUserName } = useUtils();

  const [selectedValueProv, setSelectedValueProv] = useState(initSelectedValue);
  const [optionDeleteUser] = useState(deleteUserOption);
  const [optionDeleteAccount] = useState(deleteAccountOption);

  const { permission } = useUser();

  const mapOptions = (options: InfoModulesType[]): boolean => {
    return options.some(({ validPermissions }) => {
      return validateIfHasPermissionToAccessView({
        validPermissionsView: validPermissions,
        userPermission: permission,
        action: "delete"
      });
    });
  };

  const hasValidPermission =
    action === "deleteAccount" ? mapOptions(optionDeleteAccount) : mapOptions(optionDeleteUser);

  const handleSubmitSearchCustomer = async (event: React.FormEvent) => {
    event.preventDefault();

    if (validateForm()) {
      return;
    }

    setServiceProvider(form.serviceProvider);
    setUserName(form.email);

    navigate("/delete-keycloak", {
      state: {
        form,
        action: action,
        title: title
      } as DeleteLocationState
    });
  };

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <Card className="shadow">
            <CardBody>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase text-center">{title}</h2>
                <hr />
                {/*<p className="mb-3">Ingresar los datos del cliente:</p>*/}
                <FormAlertGroup className="text-center">
                  <Alert color="info">
                    <strong>IMPORTANT:</strong> Before deleting the keycloak account or identity in CIAM, you
                    must validate that the customer has no orders in progress and that the account has been
                    previously deleted in Core Users-Catalyst.
                  </Alert>
                </FormAlertGroup>
                <hr />
                <form className="mb-1" onSubmit={handleSubmitSearchCustomer}>
                  <div className="">
                    <Text>Service Provider:</Text>
                    <Select
                      onInputChange={(value: string) => setForm({ ...form, serviceProvider: value })}
                      options={keycloakServiceProvidersOptionsList(keycloakServiceProviderUseQuery.data)}
                      onSelectedValue={setSelectedValueProv}
                      value={selectedValueProv}
                      placeholder="Select an option..."
                      textNotFound="-- Not Found --"
                    />
                  </div>
                  <div className="mb-3">
                    <Text className="">Username:</Text>
                    <Input
                      focus
                      placeholder={"email@example.com"}
                      value={form.email}
                      onChange={(e: FormEvent<HTMLInputElement>) => {
                        setForm({ ...form, email: e.currentTarget.value });
                      }}
                    />
                  </div>
                  <div className="d-grid">
                    <Button color="primary" className="mt-2 text-center" disabled={!hasValidPermission}>
                      Check User
                    </Button>
                    <Button
                      disabled={loadingValidate}
                      color="bordered"
                      type="button"
                      className="mt-2 text-center"
                      onClick={() => navigate("/")}
                    >
                      Back
                    </Button>
                  </div>
                </form>
              </div>
            </CardBody>
            {error.message && (
              <CardFooter>
                {error.message && (
                  <FormAlertGroup className="text-center">
                    <Alert color="danger">{error.message}</Alert>
                  </FormAlertGroup>
                )}
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DeleteKeycloakForm;
