import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from "react";
import { Button, Icon, Switch, Text } from "@jarvis-catalyst/ui-components";
import ModalEditTextWithEditor from "../../../components/Modal/editTextWithEditor";
import { OPTInList } from "../../../utils/listOptions";
import { ConsentChildrenType, ConstantValuesToGroup } from "../../../types";
import { UseFormReturn } from "react-hook-form";
import ConsentContext from "../../../context/ConsentContext";
import { convertFileToHtml } from "../../../utils/fileToHtml";

type Props = {
  consent: ConsentChildrenType;
  position: number;
  useStateForm: UseFormReturn<ConstantValuesToGroup, any, undefined>;
};

const Consent = ({ consent, position = 1, useStateForm }: Props): ReactElement => {
  const { positionsIntoGrup, moveElementInGroup } = useContext(ConsentContext);

  const [explicitAgree, setExplicitAgree] = useState(consent.explicitAgree ?? false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [titleModal, setTitleModal] = useState("");

  const { formState, setValue, getValues } = useStateForm;

  const keyTemplate = `template-${position}`;

  const handleInput = async (event: any) => {
    const { target: { files } } = event;
    const result = await convertFileToHtml(files);

    if (result) {
      setValue(`${keyTemplate}.legalText`, result);
      consent.legalText = result;
    } else {
      console.error("An error occurred while converting the file to html");
    }
  };

    
  useEffect(() => {
    setValue(`${keyTemplate}.position`, position);
    setValue(`${keyTemplate}.group`, Number(consent.group));
    setValue(`${keyTemplate}.simpleText`, consent.simpleText);
    setValue(`${keyTemplate}.displayText`, consent.displayText);
    setValue(`${keyTemplate}.mandatory`, consent.mandatory);
    setValue(`${keyTemplate}.code`, consent.code);
    setValue(`${keyTemplate}.version`, consent.version);
    setValue(`${keyTemplate}.legalText`, consent.legalText);
    setValue(`${keyTemplate}.explicitAgree`, explicitAgree);
  });

  if (isOpen) {
    return (
      <ModalEditTextWithEditor
        isReadOnly={!consent.editable}
        text={getValues(`${keyTemplate}.legalText`)}
        setValue={(value: string) => {
          consent.legalText = value;
          setValue(`${keyTemplate}.legalText`, value);
        }}
        isOpen
        setIsOpen={setIsOpen}
        title={titleModal}
      />
    );
  }

  return (
    <>
      <div className="col-sm-2 d-flex justify-content-center">
        <select
          disabled={["loyalty_rule", "policy_rule"].includes(consent.name)}
          className="form-select"
          {...useStateForm.register(`${keyTemplate}.position`, {
            value: position,
            required: true,
            onChange: (e: ChangeEvent<HTMLSelectElement>) => {
              const lastPosition = consent.position;
              moveElementInGroup(consent.group, lastPosition, Number(e.target.value));

              setValue(`${keyTemplate}.position`, Number(e.target.value));
            }
          })}
        >
          {positionsIntoGrup(Number(consent.group))?.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="col-sm-10 row mt-4 mb-4">
        <div className="mb-2">
          <Text className="">Categoría OPT-IN</Text>
          <select
            {...useStateForm.register(`${keyTemplate}.simpleText`, {
              required: true,
              onChange: (e) => {
                consent.simpleText = e.target.value;
              }
            })}
            className={`form-control ${formState.errors[`${keyTemplate}`]?.simpleText ? "is-invalid" : ""}`}
            disabled={!consent.editable}
          >
            <option value="">Seleccione una Categoría OPT-IN</option>
            {OPTInList().map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2">
          <div className="row">
            <Text className="">Texto Checkbox</Text>
            <div className="col-sm-10">
              <input
                disabled={!consent.editable}
                {...useStateForm.register(`${keyTemplate}.displayText`, {
                  required: true,
                  onChange: (e) => {
                    consent.displayText = e.target.value;
                  }
                })}
                className={`form-control ${
                  formState.errors[`${keyTemplate}`]?.displayText ? "is-invalid" : ""
                }`}
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-sm-3">
              <Text>Obligatoriedad</Text>
              <select
                disabled={!consent.editable}
                className="form-select"
                {...useStateForm.register(`${keyTemplate}.mandatory`, {
                  required: true,
                  onChange: (e) => {
                    consent.mandatory = e.target.value;
                  }
                })}
              >
                <option value={"no"}>No</option>
                <option value={"yes"}>Sí</option>
              </select>
            </div>
            <div className="col-sm-6">
              <Text className="">Código del Texto legal</Text>
              <input
                disabled={!consent.editable}
                type="text"
                className={`form-control ${formState.errors[`${keyTemplate}`]?.code ? "is-invalid" : ""}`}
                {...useStateForm.register(`${keyTemplate}.code`, {
                  required: true,
                  onChange(event) {
                    consent.code = event.target.value;
                  }
                })}
              />
            </div>
            <div className="col-sm-2">
              <Text>Versión</Text>
              <input
                disabled={!consent.editable}
                type="text"
                className={`form-control ${formState.errors[`${keyTemplate}`]?.version ? "is-invalid" : ""}`}
                {...useStateForm.register(`${keyTemplate}.version`, {
                  required: true,
                  onChange(event) {
                    consent.version = event.target.value;
                  }
                })}
              />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-sm-6" style={{ marginTop: "-30px", marginBottom: "-15px" }}>
              <Text>Aceptación Explicita</Text>
              <Switch
                onChange={(value) => {
                  setExplicitAgree(value);
                  consent.explicitAgree = value;
                  setValue(`${keyTemplate}.explicitAgree`, value, { shouldValidate: false });
                }}
                value={consent.explicitAgree}
                size="sm"
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "-30px" }}>
          <div className="row">
            <Text>Texto Legal</Text>
            <div className='mb-3'>
              <Text>cargar archivo</Text>
              <input className="form-control form-control-sm" id="formFileSm" type="file" onChange={handleInput}/>
            </div>
            <div className="col-sm-10">
              <textarea
                disabled
                className={`form-control ${
                  formState.errors[`${keyTemplate}`]?.legalText ? "is-invalid" : ""
                }`}
                {...useStateForm.register(`${keyTemplate}.legalText`, {
                  required: true
                })}
              />
            </div>
            <div className="col-sm-2 d-flex align-items-center">
              <Button
                onClick={() => {
                  setIsOpen(true);
                  setTitleModal("Editar Texto Legal");
                }}
              >
                <Icon name="Edit" color="white" size={24} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Consent;
