import axiosInstance from "../config/axiosIntance";
import { ResponseGetServiceProviders } from "../types";

type KeycloakServiceProviderParams = {
  token?: string;
};

export const getAllKeycloakServiceProviderService = async ({
  token
}: KeycloakServiceProviderParams): Promise<ResponseGetServiceProviders> => {
  const dataFetched = await axiosInstance.get("/keycloak/serviceProviders", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return dataFetched.data;
};
